import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import NavCard from "../../../home/nav-card"
// import PurpleButton from "../../../home/buttons/purple-button"
import { Link } from "gatsby"

const WidgetNavBar = ({ _data, _logo }) => {
  const [clicked, setclicked] = useState(true)
  let contentNavBar = []
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(widget-navbar-dark)/" }) {
          exports {
            data {
              column
              to
              navCard {
                header {
                  title
                  subtitle
                  alt
                  icon
                  to
                }
                footer {
                  link {
                    description
                    to
                  }
                }
              }
            }
          }
          frontmatter {
            logoWeb
            logoMobile
            buttonTitle1
            buttonTitle2
            buttonSize
          }
        }
      }
    `)
    contentNavBar = result.mdx.exports.data
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    contentNavBar = _data
    frontmatter = _logo
  }

  return (
    <div id="WidgetNavBar" className="WidgetNavBar" data-testid="WidgetNavBar">
      <nav
        className="navbar navbar-expand-lg navbar-light "
        data-testid="navbarWeb"
      >
        <Link to="/" className="navbar-brand d-none d-md-block row">
          <img
            src={`${frontmatter.logoWeb}`}
            alt="MAXapps"
            title="MAXapps"
            className="p-0 m-0 imgMaxapps"
          />
        </Link>
        <Link to="/" className="navbar-brand d-block d-md-none ">
          <img
            alt="MAXapps"
            title="MAXapps"
            src={frontmatter.logoMobile}
            className="p-0 m-0 imgMaxappsMobile "
          />
        </Link>
        <div className=" d-block d-lg-none   d-flex align-items-center justify-content-center justify-content-md-end  col-7  ">
          <Link to="/contact/">
            <button className="btn2 " type="button">
              {frontmatter.buttonTitle1}
            </button>
          </Link>
          <Link to="/get-started/">
            <button className="btn " type="button">
              {frontmatter.buttonTitle2}
            </button>
          </Link>
        </div>
        <button
          className="navbar-toggler "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            setclicked(!clicked)
          }}
        >
          <span
            className={clicked ? "navbar-toggler-icon" : "bi bi-x-lg"}
          ></span>
        </button>
        <div className="collapse navbar-collapse d-none d-lg-block">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {contentNavBar.map((item, index) => {
              return (
                <li className="nav-item dropdown" key={index}>
                  <Link
                    to={item.to}
                    className="nav-link dropdown-toggle links"
                    id="navbarDropdown"
                    // role="button"
                    // data-bs-toggle="dropdown"
                    // disabled="disabled"
                  >
                    {item.column}
                    {item.navCard !== null && (
                      <i className="bi bi-chevron-down navicon"></i>
                    )}
                  </Link>
                  {item.navCard !== null && (
                    <ul
                      className="dropdown-menu "
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <NavCard navCard={item.navCard} />
                      </li>
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
          {/* <PurpleButton
            data={frontmatter.buttonTitle}
            size={frontmatter.buttonSize}
          />
          <input
            className="form-control "
            type="search"
            placeholder="Search"
            aria-label="Search"
          /> */}
          <Link to="/contact/">
            <button className="btn2 " type="button">
              {frontmatter.buttonTitle1}
            </button>
          </Link>
          <Link to="/get-started/">
            <button className="btn " type="button">
              {frontmatter.buttonTitle2}
            </button>
          </Link>
        </div>
      </nav>
      <div id="sectionMobile " className=" d-block d-lg-none">
        <ul
          id="navbarSupportedContent"
          className="ulMobile panel-collapse collapse in"
        >
          <div className="row py-3 justify-content-start "></div>
          {contentNavBar.map((item, index) => {
            return (
              <div className="border-top  py-4" key={index}>
                <Link
                  to={item.to}
                  className="nav-link dropdown-toggle links"
                  id="navbarDropdown"
                  role="button"
                >
                  {item.column}
                </Link>

                <NavCard navCard={item.navCard} />
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default WidgetNavBar
