import { Link } from "gatsby"
import React, { useState } from "react"
import "./index.scss"

const WidgetHomeBtn = () => {
  const [clicked2, setclicked2] = useState(true)

  return (
    <div className="Widget-home-btn row">
      <Link to="/widgets/" className="col-9 home-wdgt-btn">
        <i className="bi bi-chevron-left"></i> Widgets
      </Link>
      <div className="col-2  d-block d-lg-none  text-end p-0 NavMobilewidget">
        <button
          className="navbar-toggler  "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#NavMobilewidget"
          aria-controls="NavMobilewidget"
          aria-expanded="true"
          aria-label="Toggle navigation"
          onClick={() => {
            setclicked2(!clicked2)
          }}
        >
          <i
            className={
              clicked2 ? "bi bi-arrows-expand" : "bi bi-arrows-collapse"
            }
          ></i>
        </button>
      </div>
    </div>
  )
}

export default WidgetHomeBtn
