import React from "react"

import { connectSearchBox } from "react-instantsearch-dom"

import "./index.scss"
export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <div id="connectSearchBox" className="searchNav" data-testid="searchNav">
      <div className="search-card">
        <form className="search-input d-flex flex-row">
          <input
            type="text"
            className="widget-search-input"
            placeholder="Search for widgets"
            onChange={e => refine(e.target.value)}
            value={currentRefinement}
            onFocus={onFocus}
          />
          {/* <button
            type="submit"
            className="search-btn"
            title="Submit your search query"
          >
            <img src="/search.svg" alt="search" className="imgBtn" />
          </button> */}
        </form>
      </div>
    </div>
  )
)
