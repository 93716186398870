import axios from "axios"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useEffect } from "react"

import "./index.scss"
import Seo from "../../seo"
import WidgetSideNav from "../../widgets/widget-side-nav"
import CodeBlock from "../../code-block"
import ZendeskButton from "../../zendesk"
import ImxLink from "../../imx-links/index"
import XmlReader from "../../xml-reader"
import Blockquote from "../../blockquote-docs"
import ImageDocs from "../docs-layout/image"
import WidgetFooter from "../../widgets/widget-body/widget-footer/widget-footer-body"
import { isLoggedIn } from "../../../services/utils"
import { Link } from "gatsby"
import LoginForm from "../../console/authentication/login/login-form"

const components = {
  pre: props => <div {...props} />,
  code: CodeBlock,
  ImxLink,
  ImageDocs,
  XmlReader,
  Blockquote,
}

const WidgetLayout = ({ data, pageTitle = "", title }) => {
  const url_post =
    "https://ybb6yegdhg.execute-api.us-east-1.amazonaws.com/default/myGatsbyFunction"

  let currentPage = {
    numberClick: 1,
    widgetName: title,
  }
  useEffect(() => {
    sendTrack()
  })
  const sendTrack = () => {
    axios
      .post(`${url_post}`, JSON.stringify(currentPage))
      .then(response => {
        console.log("Page track is sent")
      })
      .catch(error => console.error(`Error : ${error}`))
  }
  const showPageContent = isLoggedIn()
  return (
    <div id="WidgetLayout" className="WidgetLayout row ">
      <Seo />
      <div className="widget-side-nav fixed col-2 col-lg-4">
        <title>{pageTitle}</title>
        <WidgetSideNav />
      </div>
      <div
        className={`widget-doc col-12 col-lg-8 ${
          !showPageContent && "private-content-info row"
        }`}
      >
        {showPageContent ? (
          <>
            {pageTitle && <h1 className="page-title">{pageTitle}</h1>}
            <MDXProvider components={components}>
              <MDXRenderer>{data}</MDXRenderer>
            </MDXProvider>
          </>
        ) : (
          <>
            <div className="private-content-message col-md-12">
              <h1>
                This is a private content for logged in clients only
                <br />
                please <span>login</span> or{" "}
                <Link to="/console/signup/">signup</Link>
              </h1>
            </div>
            <div className="login-form-container col-md-12">
              <LoginForm />
            </div>
          </>
        )}

        <WidgetFooter />
      </div>
      <ZendeskButton />
    </div>
  )
}

export default WidgetLayout
