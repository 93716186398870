import React from "react"
import WidgetHeaderNav from "./widget-header-nav"
import WidgetNavBar from "./widget-nav-bar"
import "./index.scss"
const WidgetHeaderMain = () => {
  return (
    <div id="WidgetHeaderMainWhite" className="WidgetHeaderMainWhite">
      <WidgetHeaderNav />
      <WidgetNavBar />
    </div>
  )
}

export default WidgetHeaderMain
