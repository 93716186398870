import React, { useState } from "react"
import { Link } from "gatsby"

import SideNavCard from "./side-card-item"

import "./index.scss"
const SideCards = ({ data = [] }) => {
  const number = data.length
  const [min, setMin] = useState(10)
  return (
    <div id="NavCards" className="NavCards">
      <div className="scroll-cards">
        <ul className="cards-list">
          <li className="card-item">
            {data
              .filter((elem, ind) => ind + 1 <= min)
              .map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={`/widgets/${item.slug}`}
                    className="card-link"
                  >
                    <SideNavCard info={item} key={index} />
                  </Link>
                )
              })}
          </li>
        </ul>
        {min < number && (
          <button className="load-btn" onClick={() => setMin(min + 10)}>
            Load more
          </button>
        )}
      </div>
    </div>
  )
}
export default SideCards
