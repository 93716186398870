import React, { useState, useEffect } from "react"
import axios from "axios"

import "./index.scss"
const SideNavCard = ({ info }) => {
  const url_get =
    "https://c6u11yxrva.execute-api.us-east-1.amazonaws.com/prod/getOrderedWidgets"
  const [clicks, setClicks] = useState(0)
  useEffect(() => {
    getTrack()
  })
  const getTrack = async () => {
    await axios
      .get(`${url_get}`)
      .then(response => {
        setClicks(
          response.data.body.widgetsOrder.filter(
            item => item.widgetName === info.title
          )[0].numberClick
        )
      })
      .catch(error => console.error(`Error: ${error}`))
  }
  return (
    <div id="sideNavCard" className="sideNavCard" data-testid="sideNavCard">
      <div className="side-card-content">
        <div className="sidecard-top">
          <div className="sidecard-title">
            <p className="title">{info.title}</p>
          </div>
          <div className="sidecard-views">
            <span className="views">{clicks} View</span>
            <img src="/view.png" alt="views"></img>
          </div>
        </div>
        <div className="sidecard-text">
          <p>{info.description}</p>
        </div>
      </div>
    </div>
  )
}

export default SideNavCard
