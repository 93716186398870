import { default as React } from "react"
import { connectStateResults, Index } from "react-instantsearch-dom"
import SideCards from "../side-cards"
import "./index.scss"
const HitContent = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return (
    <div className="searchNav" data-testid="searchNav">
      <div className="HitsCount border-bottom">
        {hitCount} result{hitCount !== 1 ? `s` : ``}
      </div>
      {hitCount > 0 && <SideCards data={searchResults.hits} />}
    </div>
  )
})

const SearchResult = ({ indices, className }) => {
  return (
    <div className="className SearchResult">
      <div className="">
        {indices.map(index => (
          <Index indexName={index.name} key={index}>
            <HitContent />
          </Index>
        ))}
      </div>
    </div>
  )
}

export default SearchResult
