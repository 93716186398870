import { default as React, useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import WidgetHomeBtn from "./home-widget-button"

import StyledSearchBox from "./search-nav/styled-search-box"
import StyledSearchResult from "./search-nav/styled-search-result"

import "./index.scss"
const WidgetSideNav = () => {
  const [query, setQuery] = useState("")
  const [hasFocus, setFocus] = useState(true)
  const searchClient = algoliasearch(
    "2I1UA3A6C8",
    "df70157b629f8ab7a34d78dbba3b5ad6"
  )
  const theme = {
    foreground: "#050505",
    background: "white",
    faded: "#888",
  }
  return (
    <div id="WidgetSideNav" className="WidgetSideNav">
      <WidgetHomeBtn />

      <div
        id="NavMobilewidget"
        className="NavMobilewidget d-block- d-lg-none  panel-collapse collapse in pt-5"
      >
        <ThemeProvider theme={theme}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <StyledSearchBox
              onFocus={() => setFocus(true)}
              hasFocus={hasFocus}
            />

            <StyledSearchResult
              show={query && query.length > 0 && hasFocus}
              indices={indices}
            />
          </InstantSearch>
        </ThemeProvider>
      </div>
      <div id="NavMobilewidget" className="NavMobilewidget d-none d-lg-block">
        <ThemeProvider theme={theme}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <StyledSearchBox
              onFocus={() => setFocus(true)}
              hasFocus={hasFocus}
            />

            <StyledSearchResult
              show={query && query.length > 0 && hasFocus}
              indices={indices}
            />
          </InstantSearch>
        </ThemeProvider>
      </div>
    </div>
  )
}

const indices = [{ name: `widgetPages`, title: "widgetPages" }]

export default WidgetSideNav
