import { graphql } from "gatsby"
import React from "react"

import WidgetHeaderMain from "../components/widgets/widget-nav-header"
import WidgetLayout from "../components/layout/widget-layout"
const WidgetTemplate = ({ data }) => {
  return (
    <div className="px-4">
      <WidgetHeaderMain />

      <WidgetLayout
        data={data.mdx.body}
        pageTitle={data.mdx.frontmatter.pageTitle}
        title={data.mdx.frontmatter.title}
      />
    </div>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      slug
      frontmatter {
        title
        description
        pageTitle
      }
      body
    }
  }
`

export default WidgetTemplate
